<template>
  <div>
    <div class="step">
      <el-steps :active="active" finish-status="success" align-center>
        <el-step title="编辑关联信息" />
        <el-step title="编辑店铺资料" />
      </el-steps>
    </div>

    <Relevance
      v-if="active == 0 && detail.id"
      :dataMsg="detail"
      type-page="compile"
      @setActive="next"
      @getData1="getData1"
    />
    <shopData
      v-if="active == 1 && detail.id"
      :dataMsg="detail"
      type-page="compile"
      @setActiveUp="up"
      @getData2="getData2"
    />
  </div>
</template>

<script>
import Relevance from "./components/relevance.vue";
import shopData from "./components/shopData.vue";
import { updateShop } from "@/api/shop";
export default {
  name: "CompileShop",
  props: {
    dataMsg: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    Relevance,
    shopData
  },
  data() {
    return {
      active: 0,
      detail: {}
    };
  },

  mounted() {
    this.detail = this.$route.query;
  },
  activated() {},
  methods: {
    // 关联信息
    getData1(e) {
      this.data1 = e;
    },
    // 店铺信息
    getData2(e) {
      this.data2 = e;
      this.hint();
    },
    // 下一页
    next() {
      this.active += 1;
    },
    // 上一页
    up() {
      this.active -= 1;
    },
    // 提示
    hint(text) {
      this.$confirm(`确定更改此店铺吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.confirm();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 提交
    confirm() {
      updateShop({ id: this.$route.query, ...this.data1, ...this.data2 }).then(
        res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.$router.push(
            "./shopDetail?activeName=first&id=" +
              this.detail.shopId +
              "&name=" +
              this.detail.shopName
          );
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.step {
  padding: 30px 0;
  ::v-deep .el-step__title.is-success {
    color: #2dc0a3;
    font-weight: bold;
    font-size: 18px;
  }
  ::v-deep .el-step__title.is-process {
    color: #2dc0a3;
    font-weight: bold;
    font-size: 18px;
  }

  ::v-deep .el-step__head.is-success {
    color: #2dc0a3;
    font-weight: bold;
    font-size: 18px;
  }
  ::v-deep .el-step__icon.is-text {
    border-color: #2dc0a3;
    background: #2dc0a3;
    color: #fff;
    font-size: 18px;
    width: 40px;
    height: 40px;
  }
  ::v-deep .el-step__line-inner {
    border-color: #2dc0a3;
  }
  ::v-deep .el-step.is-horizontal .el-step__line {
    top: 20px;
  }
}
</style>
